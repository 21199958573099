.header-none {
    display: none; 
}

.header {
    display: block; 
}

.exhibition {
    display: block; 
    color: black; 
    margin-top: 20px;  
    margin-left: auto; 
    margin-right: auto; 
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif !important;
  } 

  #big-name {
    display: block; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 20px; 
    text-align: center; 
    text-decoration: none; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
    font-size: 24px !important; 
    font-weight: bold !important;  
    color: black !important; 
  } 

  #ndot {
    display: block; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 20px; 
    text-align: center; 
    text-decoration: none; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
    font-weight: normal !important; 
    font-size: 24px !important;  
    color: black !important; 
  }

  #caption {
    display: block; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 20px; 
    text-align: center; 
    text-decoration: none; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
    font-size: 18px !important; 
    font-weight: bold !important;  
    color: black !important; 
} 

#caption-card {
    display: none; 
}

#caption-card-l {
    display: inline-block; 
    padding-left: 40px; 
    padding-right: 40px; 
    padding-top: 50px; 
    padding-bottom: 65px; 
    border-radius: 5px; 
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #1e3272; 
    width: 20vw !important; 
    text-align: center; 
    text-decoration: none; 
    color: white; 
    font-size: 22px; 
    margin-right: 20px; 
} 

#caption-card-m {
    display: inline-block; 
    padding-left: 40px; 
    padding-right: 40px; 
    padding-top: 50px; 
    padding-bottom: 65px; 
    border-radius: 5px; 
    margin-bottom: 20px;
    margin-top: 20px; 
    background-color: #1e3272; 
    width: 20vw !important; 
    text-align: center; 
    text-decoration: none; 
    color: white; 
    font-size: 22px; 
} 

#caption-card-r {
    display: inline-block; 
    padding-left: 40px; 
    padding-right: 40px; 
    padding-top: 50px; 
    padding-bottom: 65px; 
    border-radius: 5px; 
    margin-bottom: 20px;
    margin-top: 20px; 
    background-color: #1e3272; 
    width: 20vw !important; 
    text-align: center; 
    text-decoration: none; 
    color: white; 
    font-size: 22px; 
    margin-left: 20px; 
} 

#caption-card-l:hover, #caption-card-m:hover, #caption-card-r:hover {
    background-color: #6376b3; 
}

.card-row-artists { 
    display: flex; 
    justify-content: center !important; 
    margin-left: auto; 
    margin-right: auto; 
} 

#art-paragraph-other {
    font-size: 18px !important;  
    text-align: center; 
} 

#caption-small {
    display: block; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 20px; 
    text-align: center; 
    text-decoration: none; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
    font-size: 16.5px !important; 
    font-weight: bold !important;  
    color: black !important; 
} 

#link {
    text-decoration: none; 
}

#image-thing-e {
    display: block; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 40px; 
    width: 50vw !important; 
} 

@media only screen and (max-width: 700px) {

    #art-paragraph {
        margin-left: 15px; 
        margin-right: 15px; 
        text-align: center; 
    }

    #art-paragraph-other {
        margin-left: 15px; 
        margin-right: 15px; 
        text-align: center; 
    } 

    #header-thing {
        justify-content: center !important; 
        text-align: center !important; 
        margin-left: auto; 
    } 

    #image-thing-e {
        width: 80vw !important; 
    } 

    #caption-card {
        display: block; 
        padding-left: 40px; 
        padding-right: 40px; 
        padding-top: 40px; 
        padding-bottom: 40px; 
        border-radius: 5px; 
        margin-top: 20px;
        background-color: #1e3272; 
        width: 70vw !important; 
        text-align: center; 
        text-decoration: none; 
        color: white; 
        font-size: 22px; 
        margin-left: auto; 
        margin-right: auto; 
    } 

    #caption-card:hover {
        background-color: #6376b3; 
    }

    #caption-card-l {
        display: none;  
    } 
    
    #caption-card-m {
        display: none;  
    } 
    
    #caption-card-r {
        display: none;   
    } 

    .card-row-artists { 
        display: none; 
    }

}