@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* ./styles/globals.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.nft-gradient {
   background: linear-gradient(101.12deg, #ff0000 27.35%, #c21313 99.99%, #ff1c1c 100%, #ff0000 100%);
}

.white-bg {
    background: rgba(255, 255, 255, 0.2);
}

.nav-h {
    height: calc(100vh - 66px);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    scroll-behavior: smooth;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.checkbox {
    opacity: 0;
    position: absolute;
}
  
.label {
    transform: scale(1.5);
}
  
.ball {
    top: 2px;
    left: 2px;
    transition: transform 0.2s linear;
}
  
/*  target the elemenent after the label*/
.checkbox:checked + .label .ball{
    transform: translateX(16px);
}
  
.fa-moon {
    color: pink;
    font-size: 9px;
}
  
.fa-sun {
    color: yellow;
    font-size: 9px;
}

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: 500ms;
    animation-duration: 500ms;
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

.flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flexBetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flexStart {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.flexStartCenter {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.flexBetweenStart {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.flexCenterStart {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
