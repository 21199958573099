#contact-head {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif !important; 
    font-size: 26px;
    display: block; 
    justify-content: center !important; 
    text-align: center !important; 
    align-items: center !important; 
    color: rgb(0, 0, 0); 
    /* color: #1e3272 !important;  */
    font-weight: bold; 
    margin-top: 20px; 
} 

#fill-out {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif !important; 
    font-size: 22px;
    display: block; 
    justify-content: center !important; 
    text-align: center !important; 
    align-items: center !important; 
    color: rgb(0, 0, 0); 
    /* color: #1e3272 !important;  */
    font-weight: bold; 
    margin-top: 10px; 
}

/* Style inputs with type="text", select elements and textareas */
input[type=text], select, textarea {
    width: 100%; /* Full width */
    padding: 12px; /* Some padding */ 
    border: 1px solid #ccc; /* Gray border */
    border-radius: 4px; /* Rounded borders */
    box-sizing: border-box; /* Make sure that padding and width stays in place */
    margin-top: 6px; /* Add a top margin */
    margin-bottom: 16px; /* Bottom margin */
    resize: vertical /* Allow the user to vertically resize the textarea (not horizontally) */
  }
  
  /* Style the submit button with a specific background color etc */
  input[type=submit] {
    background-color: #04AA6D;
    font-weight: bold;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  /* When moving the mouse over the submit button, add a darker green color */
  input[type=submit]:hover {
    background-color: #45a049;
  }
  
  /* Add a background color and some padding around the form */
  .contact-us {
    border-radius: 5px;
    background-color: #ffffff;
  } 

  #submit {
    display: block; 
    margin-left: auto; 
    margin-right: auto; 
    background-color: #000000; /* Black */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    font-size: 16px; 
    font-weight: bold; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
    border-radius: 8px; 
  } 

  #submit:hover {
    background-color: rgb(83, 83, 83); /* Not Black */ 
  }

  #the-span {
    font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
  } 

  .row {
    font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
    color: green; 
    display: flex; 
    justify-content: center; 
  }