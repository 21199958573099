.header-none {
    display: none; 
}

.header {
    display: block; 
}

.exhibition {
    display: block; 
    color: black; 
    margin-top: 20px;  
    margin-left: auto; 
    margin-right: auto; 
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif !important; 
  } 

  #head-hu {
    display: block; 
    color: black; 
    margin-top: 80px; 
    margin-left: auto; 
    margin-right: auto; 
    text-align: center; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
    font-size: 22px !important; 
    color: black !important; 
  } 

  #header-thing-hu {
    display: flex; 
    margin-left: 120px; 
    margin-right: auto; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
} 

  #caption {
    display: block; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 20px; 
    text-align: center; 
    text-decoration: none; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
    font-size: 18px !important; 
    font-weight: bold !important;  
    color: black !important; 
} 

#link {
    text-decoration: none; 
}

#image-thing-hu {
    display: block; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 40px; 
} 

@media only screen and (max-width: 600px) {

    #art-paragraph {
        margin-left: 15px; 
        margin-right: 15px; 
        text-align: center; 
    }

    #art-paragraph-other {
        margin-left: 15px; 
        margin-right: 15px; 
        text-align: center; 
    } 

    #header-thing-hu {
        justify-content: center !important; 
        text-align: center !important; 
        margin-left: auto;  
    }

    #image-thing-hu {
        width: 80vw; 
        height: auto; 
    }

} 