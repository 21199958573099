.header-none {
    display: none; 
}

.header {
    display: block; 
}

.welcome {
    display: block; 
    color: black; 
    margin-top: 4rem; 
    margin-left: auto; 
    margin-right: auto; 
  } 

  #caption {
    display: block; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 20px; 
    text-align: center; 
    text-decoration: none; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
    font-size: 18px !important; 
    font-weight: bold !important;  
    color: black !important; 
} 

#caption-card {
    display: none; 
}

#caption-card-a-l {
    display: inline-block; 
    padding-left: 40px; 
    padding-right: 40px; 
    padding-top: 40px; 
    padding-bottom: 40px; 
    border-radius: 5px; 
    margin-bottom: 20px;
    margin-top: 20px; 
    background-color: #1e3272; 
    width: 20vw !important; 
    text-align: center; 
    text-decoration: none; 
    color: white; 
    font-size: 22px; 
    margin-right: 20px; 
} 

#caption-card-a-m {
    display: inline-block; 
    padding-left: 40px; 
    padding-right: 40px; 
    padding-top: 40px; 
    padding-bottom: 40px; 
    border-radius: 5px; 
    margin-bottom: 20px;
    margin-top: 20px; 
    background-color: #1e3272; 
    width: 20vw !important; 
    text-align: center; 
    text-decoration: none; 
    color: white; 
    font-size: 22px; 
} 

#caption-card-a-r {
    display: inline-block; 
    padding-left: 40px; 
    padding-right: 40px; 
    padding-top: 40px; 
    padding-bottom: 40px; 
    border-radius: 5px; 
    margin-bottom: 20px;
    margin-top: 20px; 
    background-color: #1e3272; 
    width: 20vw !important; 
    text-align: center; 
    text-decoration: none; 
    color: white; 
    font-size: 22px; 
    margin-left: 20px; 
} 

#caption-card-a-l:hover, #caption-card-a-m:hover, #caption-card-a-r:hover {
    background-color: #6376b3; 
}

.card-row-artists { 
    display: flex; 
    justify-content: center !important; 
    margin-left: auto; 
    margin-right: auto; 
} 

#link {
    text-decoration: none; 
}

#image {
    display: block; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 40px; 
    width: 30vw; 
    height: auto; 
} 

@media only screen and (max-width: 600px) {

    #image {
        width: 70vw; 
    } 

    #caption-card {
        display: block; 
        padding-left: 40px; 
        padding-right: 40px; 
        padding-top: 40px; 
        padding-bottom: 40px; 
        border-radius: 5px; 
        background-color: #1e3272; 
        width: 70vw !important; 
        text-align: center; 
        text-decoration: none; 
        color: white; 
        font-size: 22px; 
        margin-left: auto; 
        margin-right: auto; 
    } 

    #caption-card:hover {
        background-color: #6376b3; 
    }

    #caption-card-a-l {
        display: none;  
    } 
    
    #caption-card-a-m {
        display: none;  
    } 
    
    #caption-card-a-r {
        display: none;   
    } 

    .card-row-artists { 
        display: none; 
    }

}