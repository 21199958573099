.artist {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 26px;
    display: block; 
    justify-content: center !important; 
    text-align: center !important; 
    align-items: center !important; 
    color: rgb(0, 0, 0); 
    /* color: #1e3272 !important;  */
    font-weight: bold; 
    margin-top: 20px; 
  }

#art-paragraph {
    display: flex; 
    justify-content: center; 
    margin-top: 80px;  
    margin-left: 120px; 
    margin-right: 120px; 
    background-color: #fff !important; 
    color: black !important; 
    text-align: left !important; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
    font-size: 18px !important;
    line-height: 1.7rem !important; 
  } 

  #art-paragraph-other {
    display: flex; 
    justify-content: center; 
    margin-top: 15px;  
    margin-left: 120px; 
    margin-right: 120px; 
    background-color: #fff !important; 
    color: black !important; 
    text-align: left !important; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
    font-size: 18px !important;
    line-height: 1.7rem !important; 
  } 

  #art-paragraph-bottom {
    display: flex; 
    justify-content: left !important; 
    margin-top: 15px;  
    margin-left: 120px !important; 
    /* margin-right: 120px;  */
    background-color: #fff !important; 
    color: black !important; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
    font-size: 18px !important;
    line-height: 1.7rem !important; 
  } 

  #art-paragraph-bottom-mobile {
      display: none; 
  }

  #painting-zq {
      display: block; 
      margin-left: auto; 
      margin-right: auto; 
      margin-top: 80px; 
      width: 40vw; 
      height: auto; 
  } 

  #caption {
    display: block; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 20px; 
    text-align: center; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
    font-size: 18px !important; 
    font-weight: bold !important;  
    color: black !important; 
}

@media only screen and (max-width: 600px) {

    #art-paragraph {
        margin-left: 15px; 
        margin-right: 15px; 
        text-align: center; 
    }

    #art-paragraph-other {
        margin-left: 15px; 
        margin-right: 15px; 
        text-align: center; 
    } 

    #painting-zq { 
      width: 70vw; 
      height: auto; 
  }

    #art-paragraph-bottom-mobile {
        display: flex; 
        justify-content: left; 
        margin-top: 15px;  
        margin-left: 15px; 
        margin-right: 15px; 
        background-color: #fff !important; 
        color: black !important; 
        text-align: left !important; 
        font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
        font-size: 18px !important;
        line-height: 1.7rem !important; 
          } 

    #art-paragraph-bottom {
        display: none; 
    }

}