.popup {
    position: fixed; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100vh; 
    background-color: rgb(0, 0, 0, 0.4); 

    display: flex; 
    justify-content: center; 
    align-items: center; 
    z-index: 999999999;
} 

.popup-inner {
    position: relative; 
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    padding: 32px; 
    width: 90%; 
    /* max-width: 840px;  */
    background-color: rgb(241, 236, 201); 
    z-index: 999999999; 
    font-weight: bolder;
} 

.close-btn {
    display: block !important; 
    position: absolute; 
    top: 4vh; 
    right: 4vw; 
}

.flash_me {
    font-size: 1em;
    text-transform: uppercase; 
    color: rgb(223, 0, 0); 
    animation: flasher 2.5s linear infinite;
    transition: 0.25s;
    padding: 2px; 
  }
  
  @keyframes flasher {
    50% {
      color: rgb(17, 0, 255); 
    }
  } 

  @media only screen and (max-width: 850px) { 
    .popup-inner {
      font-size: smaller;
    } 
  }