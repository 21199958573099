#the-button {
    display: inline-block; 
    margin-left: 5vw; 
    margin-top: 40px; 
    width: 110px; 
    height: 60px;  
    /* padding: 0.3em 1em;  */
    font-family: 'Roboto', Helvetica, Arial, sans-serif; 
    font-size: 18px; 
    font-weight: bold; 
    text-align: center; 
    text-decoration: none;
    color: #000000;
    border: solid 2px #000000;
    border-radius: 3px;
    transition: .4s;
} 

#the-text {
    vertical-align: middle; 
    text-align: center; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif; 
    font-size: 18px; 
    font-weight: bold; 
    text-decoration: none; 
    color: black; 
}

#the-button:hover { 
    background: #000000; 
    color: white;
}