#hours-div {
  background-size: cover !important; 
  background: url("bottom-curve.png"); 
  background-color: #BDBDBD; 
  height: 75vh; 
}

section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 135px;
}

/* Card stuff */ 
.card-row-here {
  display: flex; 
  align-content: center !important; 
  justify-content: space-evenly; 
} 

.the-card-good {
  display: flex; 
  justify-content: center !important; 
  margin-left: auto; 
  margin-right: auto;  
  width: auto; 
  height: 35vh; 
  margin-top: 5vh !important; 
  margin-bottom: 5vh !important;
}

.the-good-card {
  display: inline-block; 
  margin-left: auto; 
  margin-right: auto; 
  width: auto; 
  height: 45vh; 
} 

.the-good-card-pc {
  display: inline-block; 
  margin-left: auto; 
  margin-right: auto; 
  width: auto; 
  height: 45vh; 
} 

.the-good-card-mobile {
  display: none; 
} 

.our-favorite-card {
  display: none; 
}

/* ------------------------------- */

#house {
  float: left; 
  margin-right: 10vw; 
  margin-top: 30.5px; 
  width: auto; 
  height: 30vh; 
  border-radius: 20px;  
  border: solid; 
  border-color: #1c2e6a; 
} 

#visit {
  max-width: 50vw !important; 
  margin-top: auto;
  margin-bottom: auto; 
}

/* ------V----BOTTOM ROW----V------ */

#artist {
  float: right;
  margin-left: 8vw; 
  margin-top: 37.5px; 
  width: auto; 
  height: 30vh;
} 

#explore {
  max-width: 50vw !important; 
  margin-top: 8vh; 
}

/* ------------------------------- */

#image-thing-home {
  display: flex; 
  justify-content: center !important;  
  margin-left: auto; 
  margin-right: auto; 
  margin-top: 45px; 
  margin-bottom: auto; 
  vertical-align: middle !important;
  width: auto; 
  height: 35vh; 
} 

#image-thing-home-1 {
  display: flex; 
  justify-content: center !important;  
  margin-left: 5vw !important; 
  margin-right: auto; 
  margin-top: 45px; 
  margin-bottom: auto; 
  vertical-align: middle !important;
  width: auto; 
  height: 35vh; 
} 

#image-thing-home-2 {
  display: flex; 
  justify-content: center;  
  margin-left: auto; 
  margin-right: auto; 
  margin-top: 50px; 
  vertical-align: middle;
  max-width: 46vw !important; 
  height: auto; 
} 

/*End Card Stuff*/ 

.title {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; 
  font-size: 28px;
  font-weight: bold;
  display: flex; 
  justify-content: center !important;
  margin-left: auto; 
  margin-right: auto; 
  text-align: left; 
  color: #fff; 
  /* background-color: #0f60d1 !important;  */
  margin-top: 0px;
  margin-bottom: 1px; 
  }

  .hours{
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 20px; 
    display: flex; 
    justify-content: center !important;
    margin-left: auto; 
    margin-right: auto; 
    text-align: left; 
    color: #fff; 
    /* background-color: #0f60d1 !important;  */
    margin-top: 0px;
    margin-bottom: 10px; 
    /* color: #0f60d1;  */ 
  }

  .hoursUH{
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 20px;
    display: flex; 
    justify-content: center !important; 
    text-align: center !important; 
    color: #fff; 
    background-color: #0f60d100 !important; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 0px;
    margin-bottom: 0px; 
  }

  #hours {  
      line-height: 0.5rem; 
      /* background: #0f60d1;  */
  } 

  .hours-container {
    display: flex; 
    justify-content: center !important; 
    margin-left: auto; 
    margin-right: auto; 
    width: auto; 
    height: auto; 
    margin-top: 0px;
    margin-bottom: 0px; 
  } 

  .the-hours-container {
    display: block; 
    text-align: center !important;
    margin-left: auto; 
    margin-right: auto; 
    border-width: 0.15em; 
    border-style: solid; 
    border-radius: 5px; 
    border-color: white; 
  } 

  .days {
    display: inline-block; 
    margin-right: 30px; 
    width: auto; 
    height: auto; 
    margin-top: 0px;
    margin-bottom: 0px; 
  } 

  .days-header {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 18px; 
    text-align: left; 
    color: #fff; 
    /* background-color: #0f60d1 !important;  */
  } 

  .the-hours {
    display: inline-block; 
    width: auto; 
    height: auto; 
    margin-top: 0px;
    margin-bottom: 0px; 
  } 

  .schedule {
    font-family: 'Times New Roman', Times, serif; 
    font-size: 18px; 
    text-align: center;  
    color: #fff; 
    /* background-color: #0f60d1 !important;  */
    margin-left: 25vw; 
    margin-right: 25vw; 
  }

  .hours-header {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 18px; 
    text-align: left; 
    color: #fff; 
    /* background-color: #0f60d1 !important;  */
  } 

  .sub {
    font-weight: bold !important;
    font-size: x-large;
    display: block; 
    color: white; 
    align-items: center; 
    text-align: center; 
  } 

  .head {
    display: block; 
    color: white; 
    align-items: center; 
    text-align: center; 
  } 

  .a-head {
    display: block; 
    color: white; 
    align-items: center; 
    text-align: center; 
    text-decoration: none; 
  }

  #space {
    /* background: #0f60d1;  */
    height: 40px; 
  }

  #space-uh {
    /* background: #0f60d1;  */
    height: 20px; 
  } 

  .sep {
    height: 10vh !important; 
  } 

  .paragraph {
    color: white; 
    text-align: center; 
    font-weight: normal; 
  } 

  .paragraph-description {
    color: white; 
    text-align: center; 
    font-weight: normal; 
    width: 40vw; 
  } 

  #apt {
    color: rgb(255, 255, 255); 
    color: linear-gradient(91.12deg, #d01f00 27.35%, #c21313 99.99%, #ff1c1c 100%, #ff0000 100%);
    font-weight: bold; 
    text-decoration: none; 
    border-radius: 5px; 
    padding: 2px !important; 
    transition: 0.25s;
  } 

  #apt:hover {
    color: rgb(0, 89, 34); 
    background: #60f29d; 
    font-weight: bold; 
    text-decoration: none; 
    border-radius: 5px; 
    transition: 0.25s;
  }

  .paragraph-stuff {
    color: #000000; 
    text-align: left; 
    font-weight: normal; 
  } 

  .sub-stuff { 
    font-weight: bold !important;
    font-size: x-large;
    display: block; 
    color: #000000; 
    align-items: center; 
    text-align: left; 
  } 

  #img-stuff { 
    float: left;
    margin-left: 6vw; 
    margin-top: 46.5px; 
    width: auto; 
    height: 45vh; 
    border-radius: 50px; 
    border-color: #000000; 
    border-width: 5px; 
    border-style: dashed; 
  } 

  #image-thing-home-stuff {
    display: flex; 
    justify-content: center !important;  
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 10px; 
    margin-bottom: auto; 
    vertical-align: middle !important;
    width: auto; 
    height: 45vh; 
    border-radius: 50px; 
    border-style: dashed; 
    border-color: #000000; 
    border-width: 5px; 
  } 

  .the-good-card-stuff {
    display: inline-block; 
    margin-left: auto; 
    margin-right: auto; 
    width: auto; 
    height: 45vh; 
  } 

  #stuff {
    margin-top: 12vh; 
    max-width: 50vw !important; 
    background-color: transparent; 
  }

  @media only screen and (max-width: 1000px) {

    #hours-div {
      /* background-color: #0f60d1;  */
      height: 67vh !important; 
    } 

    #img-stuff { 
      margin-top: 70px; 
    }

  }

  @media only screen and (max-width: 900px) {

    #hours-div {
      /* background-color: #0f60d1;  */
      height: 67vh !important; 
    } 

    #image-thing-home {
      margin-top: 100px;
    }

    #explore {
      margin-top: 4vh; 
    } 

    .paragraph-description {
      width: 70vw; 
    }

    .sep {
      height: 10vh !important; 
    } 
    #stuff {
      margin-top: 0.5vh;  
      max-width: 70vw !important; 
      background-color: transparent; 
    }

  }

  @media only screen and (max-width: 850px) {

    .card-row-here {
      display: flex; 
      justify-content: center !important;
      margin-right: auto !important; 
      margin-left: auto !important; 
    } 

    /* ------------------------------- */

    #house {
      float: left;
      margin-right: auto; 
      margin-left: auto; 
      margin-top: auto;
      margin-bottom: auto;
      width: auto; 
      height: 30vh; 
      border-radius: 20px;  
      border: solid; 
      border-color: #1c2e6a; 
    } 

    #visit {
      max-width: 80vw !important; 
    }

    /* ------V----BOTTOM ROW----V------ */

    #artist {
      float: left;
      margin-right: auto; 
      margin-left: auto; 
      margin-top: auto;
      margin-bottom: auto;
      width: auto; 
      height: 30vh; 
    } 

    #explore {
      max-width: 80vw !important; 
    }

    #hours-div {
      /* background-color: #0f60d1;  */
      height: 107vh !important; 
    } 

    .sep {
      height: 10vh !important; 
    } 

    .days-header {
      font-size: 15px; 
    }

    .hours-header {
      font-size: 15px; 
    }

    .schedule {
      margin-left: 5vw; 
      margin-right: 5vw; 
    } 

    #image-thing-home {
      display: flex; 
      justify-content: center;  
      margin-left: auto; 
      margin-right: auto; 
      margin-top: 30px; 
      margin-bottom: 20px; 
      vertical-align: middle;
      max-width: 55vw !important; 
      height: auto; 
    } 

    #stuff {
      margin-top: 0.5vh;  
      max-width: 80vw !important; 
      background-color: transparent; 
    } 

    .paragraph-stuff {
      color: #000000; 
      text-align: center; 
      font-weight: bold; 
      font-size: medium; 
    } 
  
    .sub-stuff {
      display: block; 
      color: #000000; 
      align-items: center; 
      text-align: center; 
      font-size: x-large; 
    } 

    #image-thing-home-stuff {
      display: flex; 
      justify-content: center;  
      margin-left: auto; 
      margin-right: auto; 
      margin-top: 30px; 
      margin-bottom: 20px; 
      vertical-align: middle;
      max-width: 55vw !important; 
      height: auto; 
    } 
  
    #image-thing-home-2 {
      display: flex; 
      justify-content: center;  
      margin-left: auto; 
      margin-right: auto; 
      margin-top: 50px; 
      /* margin-bottom: 20px;  */
      vertical-align: middle;
      max-width: 75vw !important; 
      height: auto; 
    } 

    .the-good-card { 
      display: block; 
      width: 76vw; 
      height: auto; 
  } 

    .the-good-card-pc {
      display: none; 
    } 

    .the-good-card-mobile { 
      display: block; 
      width: 76vw; 
      height: auto; 
  } 

  .our-favorite-card { 
      display: block; 
      width: 76vw; 
      height: auto; 
  } 

  @media only screen and (max-width: 600px) {

    #hours-div {
      /* background-color: #0f60d1;  */
      height: 125vh !important; 
    } 

    .days-header {
      font-size: 11.5px; 
    }

    .hours-header {
      font-size: 11.5px; 
    } 

    .days {
      margin-right: 15px; 
    }

  }

}