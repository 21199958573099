.header-none {
    display: none; 
}

.header {
    display: block; 
}

.exhibition {
    display: block; 
    color: black; 
    margin-top: 20px;  
    margin-left: auto; 
    margin-right: auto; 
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif !important; 
  } 

  #the-pictures {
      display: flex; 
      justify-content: center !important;  
  }

  #big-name {
    display: block; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 20px; 
    text-align: center; 
    text-decoration: none; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
    font-size: 24px !important; 
    font-weight: bold !important;  
    color: black !important; 
  } 

  #ndot {
    display: block; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 20px; 
    text-align: center; 
    text-decoration: none; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
    font-weight: normal !important; 
    font-size: 24px !important;  
    color: black !important; 
  }

  #caption {
    display: block; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 20px; 
    text-align: center; 
    text-decoration: none; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
    font-size: 18px !important; 
    font-weight: bold !important;  
    color: black !important; 
} 

#art-paragraph-other {
    font-size: 18px !important;  
    text-align: center; 
} 

#caption-small {
    display: block; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 20px; 
    text-align: center; 
    text-decoration: none; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
    font-size: 16.5px !important; 
    font-weight: bold !important;  
    color: black !important; 
} 

#link {
    text-decoration: none; 
} 

#art-paragraph-bottom {
  display: flex; 
  justify-content: left !important; 
  margin-top: 15px;  
  margin-left: 120px !important; 
  /* margin-right: 120px;  */
  background-color: #fff !important; 
  color: black !important; 
  font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
  font-size: 18px !important;
  line-height: 1.7rem !important; 
} 

#art-paragraph-bottom-mobile {
    display: none; 
} 

#image-thing-at {
    display: block; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 40px; 
    width: 60vw !important; 
} 

#image-thing-at-uh {
    display: inline-block; 
    margin-right: 20px; 
    width: 40vw; 
    height: 30vw; 
    margin-top: 40px; 
    padding-top: 5vw; 
    padding-bottom: 5vw; 
    animation: fadeIn 2s;
    -webkit-animation: fadeIn 2s;
    -moz-animation: fadeIn 2s;
    -o-animation: fadeIn 2s;
    -ms-animation: fadeIn 2s;
} 

#image-thing-at-uhh {
    display: inline-block; 
    margin-left: 20px; 
    margin-top: 40px; 
    width: 30vw; 
    height: 40vw; 
    animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
    -o-animation: fadeIn 1s;
    -ms-animation: fadeIn 1s;
} 

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}


@media only screen and (max-width: 600px) {

    #art-paragraph {
        margin-left: 15px; 
        margin-right: 15px; 
        text-align: center; 
    }

    #art-paragraph-other {
        margin-left: 15px; 
        margin-right: 15px; 
        text-align: center; 
    } 

    #art-paragraph-bottom-mobile {
      display: flex; 
      justify-content: left; 
      margin-top: 15px;  
      margin-left: 15px; 
      margin-right: 15px; 
      background-color: #fff !important; 
      color: black !important; 
      text-align: left !important; 
      font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
      font-size: 18px !important;
      line-height: 1.7rem !important; 
        } 

  #art-paragraph-bottom {
      display: none; 
  } 

    #header-thing {
        justify-content: center !important; 
        text-align: center !important; 
        margin-left: auto; 
    } 

    #image-thing-at {
        width: 90vw !important; 
    } 

}