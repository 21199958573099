.header-none {
    display: none; 
}

.header {
    display: block;   
}

#header-thing {
    display: flex; 
    margin-left: 120px; 
    margin-right: auto; 
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif !important; 
}

.exhibition {
    display: block; 
    color: black; 
    margin-top: 20px; 
    margin-left: auto; 
    margin-right: auto; 
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif !important; 
  } 

  #head {
    display: block; 
    color: black; 
    margin-top: 80px; 
    margin-left: auto; 
    margin-right: auto; 
    text-align: center; 
    text-transform: uppercase; 
    text-decoration: underline; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
    font-size: 22px !important; 
    color: black !important; 
  } 

  #points {
    display: block; 
    color: black; 
    margin-top: 40px; 
    margin-left: auto; 
    margin-right: auto; 
    text-align: center; 
    text-decoration: none; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
    font-size: 18px !important; 
    color: black !important; 
  }

  #caption-thing {
    display: block; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 20px; 
    text-align: center; 
    text-decoration: none; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
    font-weight: normal !important; 
    font-size: 18px !important;  
    color: black !important; 
} 

#art-paragraph {
    display: flex; 
    justify-content: center; 
    margin-top: 80px;  
    margin-left: 120px; 
    margin-right: 120px; 
    background-color: #fff !important; 
    color: black !important; 
    text-align: left !important; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
    font-size: 18px !important;
    line-height: 1.7rem !important; 
  } 

#art-paragraph-other {
    display: flex; 
    justify-content: center; 
    margin-top: 15px;  
    margin-left: 120px; 
    margin-right: 120px; 
    background-color: #fff !important; 
    color: black !important; 
    text-align: left !important; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
    font-size: 18px !important;
    line-height: 1.7rem !important; 
  } 

#link {
    text-decoration: none; 
}

#image-thing {
    display: block; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 80px; 
}

@media only screen and (max-width: 600px) {

    #art-paragraph {
        margin-left: 15px; 
        margin-right: 15px; 
        text-align: center; 
    }

    #art-paragraph-other {
        margin-left: 15px; 
        margin-right: 15px; 
        text-align: center; 
    } 

    #header-thing {
        justify-content: center !important; 
        text-align: center !important; 
        margin-left: auto; 
    }

}