.painting-ch {
    display: block; 
    justify-content: space-evenly;
    margin-top: 80px; 
    width: 45vw; 
    height: auto; 
} 

.painting-ch-sg {
  display: block; 
  justify-content: space-evenly;
  margin-top: 80px; 
  width: 43.25vw; 
  height: auto; 
} 

.coming-storm {
  display: block; 
  justify-content: space-evenly;
  margin-top: 65px; 
  width: 45vw; 
  height: auto; 
} 

.painting-ch-mobile-wide { 
  display: none; 
} 

.painting-ch-small {
    display: block; 
    justify-content: space-evenly;
    margin-top: 80px; 
    width: 25.5vw; 
    height: auto; 
} 

.painting-ch-small-2 {
    display: block; 
    justify-content: space-evenly;
    margin-top: 80px; 
    width: 25.25vw; 
    height: auto; 
}

.word-ch-sg{
  display: block; 
  font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
  text-align: center; 
  width: 43.25vw; 
  height: 20px !important;
  font-size: 18px !important; 
  font-weight: bold !important;  
  color: black !important; 
}

.word-ch-1{
    display: block; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
    text-align: center; 
    width: 45vw; 
    height: 20px !important;
    font-size: 18px !important; 
    font-weight: bold !important;  
    color: black !important; 
}

.word-ch-2{
    display: block; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
    text-align: center; 
    width: 25.5vw; 
    height: 20px !important;
    font-size: 18px !important; 
    font-weight: bold !important;  
    color: black !important; 
} 

#painting-ch {
    display: inline-block; 
    justify-content: space-evenly;
    margin-top: 80px; 
    width: 35.125vw; 
    height: auto; 
} 

#painting-ch-other {
    display: inline-block; 
    justify-content: space-evenly;
    margin-top: 80px; 
    width: 20vw; 
    height: auto; 
} 

#only-computer {
  display: block !important; 
}

#small-caption-ch { 
    display: inline-block; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
    text-align: center; 
    justify-content: space-evenly; 
    width: 20vw; 
    height: 20px; 
    font-size: 18px !important; 
    font-weight: bold !important;  
    color: black !important; 
}

#caption-ch-1 {
  display: block; 
  font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
  justify-content: space-between;
  text-align: center; 
  width: 35.125vw; 
  height: 20px !important;
  font-size: 18px !important; 
  font-weight: bold !important;  
  color: black !important; 
}

#caption-ch-2 {
  display: block; 
  font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
  justify-content: space-between;
  text-align: center; 
  width: 35.125vw; 
  height: 20px !important; 
  font-size: 18px !important; 
  font-weight: bold !important;  
  color: black !important; 
} 

#caption-ch-11 {
  display: block; 
  font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
  justify-content: space-between;
  text-align: center; 
  height: 20px !important;
  width: 35.125vw; 
  font-size: 18px !important; 
  font-weight: bold !important;  
  color: black !important; 
} 

#caption-ch-22 { 
  display: block; 
  font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
  justify-content: space-between;
  text-align: center; 
  height: 20px !important;
  width: 35.125vw; 
  font-size: 18px !important; 
  font-weight: bold !important;  
  color: black !important; 
} 

@media only screen and (max-width: 705px) { 

  .painting-ch-mobile-wide { 
    margin-top: 40px; 
    display: block; 
    width: 85vw; 
    height: auto; 
    margin-left: auto; 
    margin-right: auto; 
  } 

  #only-computer {
    display: none !important; 
  }
  
}