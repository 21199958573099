.header-none {
    display: none; 
}

.header {
    display: block; 
}

.exhibition {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif !important; 
    font-size: 26px;
    display: block; 
    justify-content: center !important; 
    text-align: center !important; 
    align-items: center !important; 
    color: rgb(0, 0, 0); 
    font-weight: bold; 
    margin-top: 20px !important; 
  } 

  #caption {
    display: block; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 20px; 
    text-align: center; 
    text-decoration: none; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
    font-size: 18px !important; 
    font-weight: bold !important;  
    color: black !important; 
} 

#link {
    text-decoration: none; 
}

#image-thing {
    display: block; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 40px; 
    width: 45vw; 
} 

#paragraph {
    display: flex; 
    justify-content: center; 
    margin-top: 15px;  
    margin-left: 120px; 
    margin-right: 120px; 
    background-color: #fff !important; 
    color: black !important; 
    text-align: center !important; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
    font-size: 18px !important;
    line-height: 1.7rem !important; 
} 

@media only screen and (max-width: 600px) {

    #paragraph {
        margin-left: 15px; 
        margin-right: 15px; 
    } 

    #image-thing {
        display: block; 
        margin-left: auto; 
        margin-right: auto; 
        margin-top: 40px; 
        width: 80vw;  
    } 

}