.artist {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 26px;
    display: block; 
    justify-content: center !important; 
    text-align: center !important; 
    align-items: center !important; 
    color: rgb(0, 0, 0); 
    font-weight: bold; 
    margin-top: 20px; 
  }

  #header {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    display: block; 
    justify-content: left !important; 
    text-align: left !important; 
    align-items: center !important; 
    color: rgb(0, 0, 0);  
    margin-top: 40px; 
    margin-left: 120px !important; 
  }

#art-paragraph {
    display: flex; 
    justify-content: center; 
    margin-top: 80px;  
    margin-left: 120px; 
    margin-right: 120px; 
    background-color: #fff !important; 
    color: black !important; 
    text-align: left !important; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
    font-size: 18px !important;
    line-height: 1.7rem !important; 
  } 

  #art-paragraph-other {
    display: flex; 
    justify-content: center; 
    margin-top: 15px;  
    margin-left: 120px; 
    margin-right: 120px; 
    background-color: #fff !important; 
    color: black !important; 
    text-align: left !important; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
    font-size: 18px !important;
    line-height: 1.7rem !important; 
  } 

  #art-paragraph-bottom {
    display: flex; 
    justify-content: left !important; 
    margin-top: 15px;  
    margin-left: 120px !important; 
    /* margin-right: 120px;  */
    background-color: #fff !important; 
    color: black !important; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
    font-size: 18px !important;
    line-height: 1.7rem !important; 
  } 

  #art-paragraph-bottom-mobile {
      display: none; 
  }

  #painting-cp {
      display: block; 
      margin-left: auto; 
      margin-right: auto; 
      margin-top: 80px; 
      width: 30vw; 
      height: auto; 
  }

  #caption-cp {
    display: block; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 20px; 
    width: 30vw !important; 
    text-align: center; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
    font-size: 18px !important; 
    font-weight: bold !important;  
    color: black !important; 
}

.the-words-cp {
  display: flex; 
  height: 20px !important; 
  margin-bottom: 10px; 
  justify-content: center !important;
  justify-content: space-evenly !important;
  margin-left: auto; 
  margin-right: auto; 
} 

#image-mobile-cp {
  display: none; 
}

#caption-mobile-cp {
  display: none; 
} 

#painting-wide-cp {
  display: block; 
  margin-left: auto; 
  margin-right: auto; 
  margin-top: 80px; 
  width: 50vw; 
  height: auto; 
}

@media only screen and (max-width: 620px) {

    #art-paragraph {
        margin-left: 15px; 
        margin-right: 15px; 
        text-align: center; 
    }

    #art-paragraph-other {
        margin-left: 15px; 
        margin-right: 15px; 
        text-align: center; 
    }

    #art-paragraph-bottom-mobile {
        display: flex; 
        justify-content: left; 
        margin-top: 15px;  
        margin-left: 15px; 
        margin-right: 15px; 
        background-color: #fff !important; 
        color: black !important; 
        text-align: left !important; 
        font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
        font-size: 18px !important;
        line-height: 1.7rem !important; 
          } 

          #image-mobile-cp {
            display: block !important; 
            margin-left: auto; 
            margin-right: auto; 
            margin-top: 40px; 
            width: 60vw; 
            height: auto; 
        } 

        #caption-mobile-cp {
          display: block; 
          margin-left: auto; 
          margin-right: auto; 
          margin-top: 5px; 
          height: 5px; 
          text-align: center; 
          text-decoration: none; 
          font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
          font-size: 18px !important; 
          font-weight: bold !important;  
          color: black !important; 
      } 

          .the-words-cp {
            display: none !important; 
          }

          #caption-cp {
            display: none !important;  
          } 

          #painting-wide-cp {
            display: none; 
          }

    #art-paragraph-bottom {
        display: none; 
    }

}