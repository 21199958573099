
:root{
  --mainColor: #ffffff; 
  --mainColorLight: #5767aa; 
  --secondaryColor: #DB2B39; 
  --textColor: #1e3272; 
} 

.logo {
  display: inline-block; 
} 

.mobile-logo {
  display: none; 
} 

.ml {
  display: none; 
}

header {
  display: flex; 
  justify-content: center; 
  height: 60px; 
  padding: 0 2rem; 
  background-color: var(--mainColor); 
  color: var(--textColor); 
  margin-left: auto !important;  
  margin-right: auto !important; 
} 

nav a{
  margin-left: 2rem;  
  margin-right: 2rem; 
  vertical-align: middle; 
  color: var(--textColor); 
  text-decoration: none; 
  flex-wrap: wrap; 
} 

nav a:hover{
  color: var(--secondaryColor); 
} 

header .nav-btn {
  padding: 5px; 
  cursor: pointer; 
  background: transparent; 
  border: none; 
  outline: none; 
  color: var(--textColor); 
  visibility: hidden; 
  opacity: 0; 
  font-size: 1.8rem; 
} 

@media only screen and (max-width: 1070px) {
  header .nav-btn {
    justify-content: right; 
    visibility: visible; 
    opacity: 1; 
    padding-top: 20px; 
    margin-right: -10px;
  } 

  .logo {
    display: none; 
  } 
  
  .mobile-logo {
    display: block; 
  } 

  .ml {
      max-height: 50px !important; 
      width: auto;
      margin-top: 12px;
      height: auto;
      position: relative;
      margin-right: auto !important;
      margin-left: -10px !important;
      display:block; 

  }

  header {
    justify-content: space-between;
  } 

  header nav {
    position: fixed;
    top: 0;
    left: 0; 
    height: 100%;
    width: 100%;
    display: flex; 
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: var(--mainColor);
    transition: 1s;
    transform: translateY(-100vh); 
    z-index: 999; 
  } 

  header .responsive_nav {
    transform: none; 
  }  

  nav .nav-close-btn {
    position: absolute; 
    top: 2rem; 
    right: 2rem; 
  } 

  nav a {
    font-size: 1.5rem;
  }
} 

@media only screen and (max-width: 362px) {

  .ml {
    max-height: 30px !important; 
    margin-bottom: 10px; 
    margin-top: 20px; 
    width: auto; 
    height: auto;
    position: relative;
    margin-left: -10px !important;
    display:block; 
  } 

} 