.artist {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 26px;
    display: block; 
    justify-content: center !important; 
    text-align: center !important; 
    align-items: center !important; 
    color: rgb(0, 0, 0); 
    /* color: #1e3272 !important;  */
    font-weight: bold; 
    margin-top: 20px; 
  }

#art-paragraph {
    display: flex; 
    justify-content: center; 
    margin-top: 80px;  
    margin-left: 120px; 
    margin-right: 120px; 
    background-color: #fff !important; 
    color: black !important; 
    text-align: left !important; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
    font-size: 18px !important;
    line-height: 1.7rem !important; 
  } 

  .the-paintings {
      display: flex; 
      justify-content: center !important;
      justify-content: space-evenly !important;
      margin-left: auto; 
      margin-right: auto; 
  }

  .the-words {
    display: flex; 
    height: 20px !important; 
    margin-bottom: 10px; 
    justify-content: center !important;
    justify-content: space-evenly !important;
    margin-left: auto; 
    margin-right: auto; 
} 

.the-wordss {
    display: flex; 
    height: 20px !important;
    justify-content: center !important;
    justify-content: space-evenly !important;   
    margin-left: auto; 
    margin-right: auto; 
    margin-bottom: 20px; 
} 

  #painting-ab {
      display: inline-block; 
      justify-content: space-evenly;
      margin-top: 80px; 
      width: 341px; 
      height: 450px; 
  }

  #painting-ab-sold {
    display: inline-block; 
    justify-content: space-evenly;
    margin-top: 40px; 
    width: 371px; 
    height: 490px; 
} 

#painting-ab-sold-thing {
    display: inline-block; 
    justify-content: space-evenly;
    margin-top: 50px; 
    width: 381px; 
    height: 480px; 
}

  #caption-ab-1 {
    display: block; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
    justify-content: space-between;
    text-align: center; 
    width: 341px; 
    height: 20px !important;
    font-size: 18px !important; 
    font-weight: bold !important;  
    color: black !important; 
}

#caption-ab-2 {
    display: block; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
    justify-content: space-between;
    text-align: center; 
    width: 341px; 
    height: 20px !important; 
    font-size: 18px !important; 
    font-weight: bold !important;  
    color: black !important; 
} 

#caption-ab-11 {
    display: block; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
    justify-content: space-between;
    text-align: center; 
    height: 20px !important;
    width: 341px; 
    font-size: 18px !important; 
    font-weight: bold !important;  
    color: black !important; 
} 

#caption-ab-22 { 
    display: block; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
    justify-content: space-between;
    text-align: center; 
    height: 20px !important;
    width: 341px; 
    font-size: 18px !important; 
    font-weight: bold !important;  
    color: black !important; 
} 

#caption-ac {
    display: block; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
    text-align: center; 
    font-size: 18px !important; 
    font-weight: bold !important;  
    color: black !important; 
}

#image-mobile {
    display: none; 
} 

#image-mobile-sold {
    display: none; 
}

#caption-mobile {
    display: none; 
} 

#only-mobile {
    display: none !important; 
}

@media only screen and (max-width: 705px) { 

    #image-mobile {
        display: block !important; 
        margin-left: auto; 
        margin-right: auto; 
        margin-top: 40px; 
        width: 341px; 
        height: 450px; 
    } 

    #image-mobile-sold {
        display: block !important; 
        margin-left: auto; 
        margin-right: auto; 
        width: 371px; 
        height: 490px; 
    } 

    #caption-mobile {
        display: block; 
        margin-left: auto; 
        margin-right: auto; 
        margin-top: 10px; 
        text-align: center; 
        text-decoration: none; 
        font-family: 'Roboto', Helvetica, Arial, sans-serif !important; 
        font-size: 18px !important; 
        font-weight: bold !important;  
        color: black !important; 
    } 

    #only-mobile {
        display: block !important; 
    }

    .the-paintings {
        display: none; 
    } 

    #painting-ab-sold {
        display: none; 
    }

    .the-words {
        display: none; 
    } 

    .the-wordss {
        display: none; 
    }

    #painting-ab {
        display: none; 
    } 

    #caption-ab-1 {
        display: none; 
    } 

    #caption-ab-11 {
        display: none; 
    } 

    #caption-ab-2 {
        display: none; 
    } 

    #caption-ab-22 {
        display: none; 
    } 

}

@media only screen and (max-width: 600px) {

    #art-paragraph {
        margin-left: 15px; 
        margin-right: 15px; 
        text-align: center; 
    }

}