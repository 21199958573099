.root {
    height: 100%; 
    padding-left: 5px; 
    padding-right: 5px; 
} 

.media {
    display: block;  
    width: auto; 
    height: 60vh; 
    margin-top: 10px; 
}

.card-content {
    width: 100%; 
    height: auto; 
    display: 'flex'; 
    justify-content: 'space-between'; 
    vertical-align: middle;
} 

.added {
    display: block; 
    justify-content: center !important; 
    text-decoration: none; 
    font-size: 18px !important; 
    font-weight: bold !important;  
    color: black !important; 
    text-align: center !important; 
    align-items: center !important; 
    margin-left: 35px !important;
}

.view-cart {
    display: block; 
    justify-content: center !important;
    text-decoration: none; 
    font-size: 18px !important; 
    font-weight: bold !important;  
    text-decoration: underline; 
    color: rgb(3, 149, 3) !important; 
    text-align: center !important;
    align-items: center !important; 
    margin-left: 45px !important; 
}

@media only screen and (max-width: 600px) {

.added {
    margin-left: 65px !important; 
}

.view-cart {
    margin-left: 70px !important; 
}

}

