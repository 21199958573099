.header-none {
    display: none; 
}

.header {
    display: block; 
}

.the-gallery {
    font-family: 'Times New Roman', Times, serif; 
    font-size: 26px;
    display: block; 
    justify-content: center !important; 
    text-align: center !important; 
    align-items: center !important; 
    color: rgb(0, 0, 0); 
    /* color: #1e3272 !important;  */
    font-weight: bold; 
    margin-top: 20px; 
  } 

  .slider {
    position: relative;
    margin-top: 40px !important; 
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image {
    width: 56vw;
    height: auto; 
    margin-top: 40px !important; 
    margin-bottom: 20px !important; 
  }
  
  .right-arrow {
    position: absolute;
    top: 45%;
    right: 12vw;
    font-size: 4rem;
    color: #1e3272; 
    z-index: 2;
    cursor: pointer;
    user-select: none;
  }
  
  .left-arrow {
    position: absolute;
    top: 45%;
    left: 12vw;
    font-size: 4rem;
    color: #1e3272; 
    z-index: 2;
    cursor: pointer;
    user-select: none;
  } 

  .right-arrow:hover {
    color: #6376b3 !important; 
  } 

  .left-arrow:hover {
    color: #6376b3 !important; 
  } 
  
  .slide {
    opacity: 0;
    transition-duration: 1s ease;
  }
  
  .slide.active {
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
  } 

  #awesome {
    margin-bottom: 40px !important; 
  }

  #big-name {
    display: block; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 20px; 
    text-align: center; 
    text-decoration: none; 
    font-family: 'Times New Roman', Times, serif; 
    font-size: 24px !important; 
    font-weight: bold !important;  
    color: black !important; 
  } 

  #ndot {
    display: block; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 20px; 
    text-align: center; 
    text-decoration: none; 
    font-family: 'Times New Roman', Times, serif; 
    font-weight: normal !important; 
    font-size: 24px !important;  
    color: black !important; 
  }

  #caption {
    display: block; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 20px; 
    text-align: center; 
    text-decoration: none; 
    font-family: 'Times New Roman', Times, serif; 
    font-size: 18px !important; 
    font-weight: bold !important;  
    color: black !important; 
} 

#art-paragraph-other {
    font-size: 18px !important;  
    text-align: center; 
} 

#caption-small {
    display: block; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 20px; 
    text-align: center; 
    text-decoration: none; 
    font-family: 'Times New Roman', Times, serif; 
    font-size: 16.5px !important; 
    font-weight: bold !important;  
    color: black !important; 
} 

#link {
    text-decoration: none; 
}

#image-thing-at {
    display: block; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 40px; 
    width: 60vw !important; 
    height: auto; 
} 

@media only screen and (max-width: 600px) {

    #art-paragraph {
        margin-left: 15px; 
        margin-right: 15px; 
        text-align: center; 
    }

    #art-paragraph-other {
        margin-left: 15px; 
        margin-right: 15px; 
        text-align: center; 
    } 

    #header-thing {
        justify-content: center !important; 
        text-align: center !important; 
        margin-left: auto; 
    } 

    #image-thing-at {
        width: 80vw !important; 
    } 

    .image {
        width: 83vw !important; 
    } 

    .right-arrow {
        right: 5vw; 
        font-size: 4rem;
        color: #ff00006b; 
      }
      
      .left-arrow {
        left: 5vw; 
        font-size: 4rem;
        color: #ff00006b; 
      } 

      .right-arrow:hover {
        color: #ff0000 !important; 
      }
      
      .left-arrow:hover {
        color: #ff0000 !important; 
      } 

}