.welcome {
    display: block; 
    color: red !important; 
    margin-top: 5rem; 
    margin-left: auto; 
    margin-right: auto; 
    text-align: center;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  } 

  .address:hover {
    color: white !important; 
    background-color: #02064a; 
    border-radius: 5px;
  }

  #landing-div {
    height: 100vh; 
    background-size: cover !important; 
    /* background: url("5570863.jpg");  */
    background: linear-gradient( to bottom, rgba(18, 18, 18, 0.644) 0%, rgba(70, 70, 71, 0.8) 100% ), url("gallery.jpg");
  } 

  .custom-shape-divider-top-1680282774 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
  }

  .custom-shape-divider-top-1680282774 svg {
    position: relative;
    display: block;
    width: calc(160% + 1.3px);
    height: 269px;
  }

  .custom-shape-divider-top-1680282774 .shape-fill {
    fill: #BDBDBD;
  }

  #curve-div {
    height: 61vh; 
    /* background-size: cover !important; 
    background: url("curve.png"); 
    background-color: transparent;  */ 
    background-color: #bdbdbd;  
  } 

  #border-div {
    border: #1c2e6a;
    border-style: solid; 
    border-radius: 20px; 
    border-width: 2px; 
    width: fit-content; 
    padding-top: 5 !important; 
    padding-left: 5 !important; 
    padding-right: 5 !important; 
    padding-bottom: 5px; 
    margin-left: auto; 
    margin-right: auto; 
    background-color: #1c2e6a;
  }

  #uh {
      color: #1f6edc !important; 
      margin-top: 20px; 
  } 

  .top-message {
    width: 80vw; 
    border-radius: 65px; 
    background-color: #1f6edc; 
    display: block; 
    margin-left: auto; 
    margin-right: auto; 
    padding: 10px 10px 10px 10px; 
    margin-top: 15px; 
    margin-bottom: 40px; 
  } 

  .inner-message {
    width: 70vw; 
    display: block; 
    margin-left: auto; 
    margin-right: auto; 
    border-radius: 15px; 
    border-style: double; 
    border-color: #fff; 
  }

  .celebrate {
    color: #ffffff !important; 
    display: block; 
    margin-top: 26px; 
    margin-left: auto; 
    margin-right: auto; 
    text-transform: uppercase; 
    text-decoration: underline; 
    font-weight: bold; 
    font-size: 1.5em;
    text-align: center; 
   } 

   .from {
    color: #ffffff !important; 
    display: block; 
    margin-top: 26px; 
    margin-left: auto; 
    margin-right: auto; 
    font-weight: normal; 
    font-size: 1em;
    text-align: center; 
   } 

   .from-1 {
    color: #ffffff !important; 
    display: block; 
    margin-top: 15px; 
    margin-left: auto; 
    margin-right: auto; 
    font-weight: bold; 
    font-size: 1.2em;
    text-align: center; 
   } 

   .from-2 {
    color: #ffffff !important; 
    display: block; 
    margin-top: 20px; 
    margin-left: auto; 
    margin-right: auto; 
    font-weight: bold; 
    font-size: 1.1em;
    text-align: center; 
    padding-right: 10px; 
    padding-left: 10px; 
   } 

   .from-3 {
    color: #ffffff !important; 
    display: block; 
    margin-left: auto; 
    margin-right: auto; 
    font-weight: bold; 
    font-size: 1em;
    text-align: center; 
    padding-right: 10px; 
    padding-left: 10px; 
    padding-bottom: 10px; 
   } 

   .blink_me {
    font-size: 0.9em;
    text-transform: uppercase; 
    color: rgb(249, 97, 97); 
    /* animation: blinker 1.5s linear infinite; */
    animation: none; 
    transition: 0.25s;
    padding: 2px; 
  }

  .blink_me:hover {
    color:rgb(255, 255, 255); 
    background:#cc4646; 
    animation: none; 
    transition: 0.25s;
    border-radius: 5px; 
  }
  
  @keyframes blinker {
    50% {
      color: yellow; 
    }
  } 

  #caption {
    display: block; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 20px; 
    text-align: center; 
    text-decoration: none; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif; 
    font-size: 18px !important; 
    font-weight: bold !important;  
    color: black !important; 
} 

#caption-pp {
  display: block; 
  margin-left: auto; 
  margin-right: auto; 
  margin-top: 20px; 
  text-align: center; 
  text-decoration: none; 
  font-family: 'Roboto', Helvetica, Arial, sans-serif; 
  font-size: 18px !important; 
  font-weight: bold !important;  
  color: rgb(28, 46, 106) !important; 
} 

#caption-pp-u {
  display: block; 
  margin-left: auto; 
  margin-right: auto; 
  margin-top: 20px; 
  text-align: center; 
  text-decoration: underline; 
  font-family: 'Roboto', Helvetica, Arial, sans-serif; 
  font-size: 22px !important; 
  font-weight: bold !important;  
  color: rgb(28, 46, 106) !important; 
} 

#caption-pp-l {
  display: block; 
  margin-left: auto; 
  margin-right: auto; 
  margin-top: 15px; 
  text-align: center; 
  text-decoration: none; 
  font-family: 'Roboto', Helvetica, Arial, sans-serif; 
  font-size: 18px !important; 
  font-weight: bold !important;  
  color: rgb(255, 255, 255) !important; 
  background-color: rgb(28, 46, 106) !important;
  width: fit-content !important; 
  padding: 5px 5px !important; 
  border-radius: 10px !important; 
} 

#caption-pp-l:hover {
  background-color: rgb(255, 0, 0) !important; 
} 

.Toastify__progress-bar {
  --toastify-color-progress-light: linear-gradient(
    to right,
    #d94c4c,
    #fa5a5a,
    #ff0000,
    #dc3434,
    #d65656,
    #ff2d55
  );
}

.american {
  display: block; 
  text-align: center; 
  margin-left: auto; 
  margin-right: auto; 
  margin-top: 15vh;
  font-size: 40px;
  font-family: 'Avenir Black', 'Roboto', Helvetica, Arial, sans-serif; 
  background: linear-gradient(50deg, red, red, red, orange, yellow, green, blue, purple, purple, purple);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: rainbow 5s linear infinite;
} 

@keyframes rainbow {
  0%, 100% {
    background-position: 0 0;
  }
  50% {
    background-position: 100% 0;
  }
} 

.gallery {
  display: block; 
  text-align: center; 
  margin-left: auto; 
  margin-right: auto; 
  margin-top: 15vh;
  font-size: 40px;
  font-family: 'Avenir Black', 'Roboto', Helvetica, Arial, sans-serif; 
  color: white; 
} 

  .magAndMyst {
    color: #ffffff !important; 
    display: block; 
    margin-top: 20px; 
    margin-left: 10px;  
    margin-right: 10px;  
    text-transform: uppercase; 
    text-align: center; 
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  } 

  #moreInfo {
    color: #1f6edc !important; 
    display: block; 
    justify-content: center !important; 
    padding-left: 15px; 
    padding-right: 15px; 
    padding-top: 10px; 
    padding-bottom: 10px; 
    margin-left: auto; 
    margin-right: auto; 
    font-size: 16px; 
    font-weight: bold; 
    text-align: center; 
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  } 

  .big-words {
    color: #1f6edc !important; 
    display: block; 
    margin-top: 26px; 
    margin-left: auto; 
    margin-right: auto; 
    text-transform: uppercase; 
    font-weight: bold; 
    font-size: 18px;
    text-align: center; 
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  } 

  .other-words {
    color: #1f6edc !important; 
    display: block; 
    margin-top: 5px; 
    margin-left: auto; 
    margin-right: auto; 
    text-transform: uppercase; 
    font-weight: 550; 
    font-size: 16px;
    text-align: center; 
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  }

  .announcement-card { 
    padding-top: 5px;  
    padding-left: 5px;  
    padding-right: 5px;  
    padding-bottom: 5px;  
    display: block; 
    margin-left: auto; 
    margin-right: auto; 
    border-radius: 35px; 
    border: #1f6edc; 
    border-style: solid; 
    border-width: 4px; 
    width: 60vw; 
    height: auto; 
    background-color: #dbdbdb62; 
  }

  #why {
    text-decoration: none !important; 
  } 

  #please {
    color: #1f6edc !important; 
    display: flex; 
    justify-content: center !important;
    margin-left: auto; 
    margin-right: auto; 
    font-size: 16px; 
    font-weight: 550; 
    text-align: left; 
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  } 

  .christmas-green {
    color: green; 
  }

  .christmas-red {
    color: red; 
  }

  .artist-link {
    color: #1f6edc !important; 
    display: flex; 
    justify-content: center !important;
    margin-left: auto; 
    margin-right: auto; 
    font-size: 16px; 
    font-weight: bold; 
    text-align: left; 
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  } 

  .artist-link:hover { 
    width: 15vw; 
    color: #ffffff !important;  
    background-color: #1f6edc; 
    border-radius: 10px; 
    text-decoration: none; 
  }

  .the-line {
    height: 1px; 
    background-color: #1f6edc !important; 
    border: none; 
  }

  .reachOut {
    display: block; 
    justify-content: center; 
  }

  #addy {
    display: block; 
    justify-content: center; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif; 
    font-size: 18px; 
    font-weight: bold; 
    text-decoration: none; 
    text-align: center; 
    color: rgb(243, 243, 243); 
  } 

  #addy-click {
    display: block; 
    justify-content: center; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif; 
    font-size: 18px;
    font-weight: bold; 
    text-decoration: none; 
    text-align: center !important; 
    align-items: center !important; 
    color: rgb(243, 243, 243); 
    width: fit-content; 
    margin-left: auto !important; 
    margin-right: auto !important; 
  } 

  #addy-click:hover { 
    display: block; 
    justify-content: center; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif; 
    font-weight: bold; 
    text-decoration: none; 
    text-align: center; 
    color: rgb(249, 58, 58); 
  } 

  #addyBottom {
    display: block; 
    justify-content: center; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif; 
    font-size: 18px; 
    font-weight: bold; 
    text-decoration: none; 
    text-align: center; 
    color: rgb(243, 243, 243); 
    margin-bottom: 50px;
  }

  #info {
    line-height: 2rem; 
    /* margin-bottom: 200px !important;  */
  }

  #blue { 
    background-color: #1f6edc !important;  
    position: static;
  } 

  #blue-up {
    background-color: #1f6edc !important;
    height: 5rem; 
    /* margin-top: 150px !important; */
    position: static; 
  }

  #blue-down {
    background-color: #1f6edc !important;
    height: 5rem; 
    margin-top: 0px; 
    position: static;
  }

  #top {
    height: 75vh; 
    /* margin-bottom: 225px; */
    position: static;
  }

  #iconStuff {
    display: inline-block;
    display: flex; 
    justify-content: center !important; 
    align-items: center !important; 
    align-content: center !important; 
    margin-bottom: 10px !important;  
  } 

  #twitter {
    margin-right: 8px;
    margin-bottom: 10px;
    } 
  
  #twitter:hover {
    transform: translateY(-5px); 
  }

  #instagram img{
    margin-right: 8px; 
    margin-left: 8px; 
    margin-bottom: 10px !important;
  }

  #instagram:hover {
    transform: translateY(-5px); 
  }

  #linkedin img{
    margin-left: 8px; 
    margin-bottom: 10px !important;
  } 

  #linkedin:hover {
    transform: translateY(-5px); 
  }

  #bottom {
    background-color: #fff; 
    margin-top: 20px;
    margin-bottom: 20px;
  } 

  #bottom-1 {
    background-color: #fff; 
    margin-top: 20px;
    height: 55vh; 
  } 

  #br-mobile {
    display: none; 
  }

  #fung-shway {
    display: block; 
    margin-top: 350px; 
    margin-bottom: 50px; 
    margin-left: auto !important; 
    margin-right: auto !important; 
    height: auto !important; 
    width: 30vw; 
  } 

  #p-top {
    display: flex; 
    justify-content: center; 
    margin-bottom: 20px; 
    margin-left: 120px; 
    margin-right: 120px; 
    background-color: #fff !important; 
    color: black !important; 
    text-align: left !important; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif; 
    font-size: 18px !important;
    line-height: 1.7rem !important; 
  }

  #p-bottom {
    display: flex; 
    justify-content: center; 
    margin-top: 20px; 
    margin-left: 120px; 
    margin-right: 120px; 
    background-color: #fff !important; 
    color: black !important; 
    text-align: left !important; 
    font-family: 'Roboto', Helvetica, Arial, sans-serif; 
    font-size: 18px !important;
    line-height: 1.7rem !important; 
  } 

  @media only screen and (min-width: 2800px) { 
    #curve-div {
      height: 135vh; 
    } 
  }

  @media only screen and (max-height: 850px) { 
    #bottom-1 {
      height: 60vh; 
    } 
  }

  @media only screen and (max-width: 600px) {

    .american {
      margin-top: 10vh;
    } 

    #addy {
      font-size: smaller; 
    } 
  
    #addy-click {
      font-size: smaller; 
    } 
  
    #addy-click:hover { 
      color: rgb(255, 97, 97); 
    } 
  
    #addyBottom {
      font-size: smaller; 
    }

    #landing-div {
      height: 100vh; 
      /* background: white;  */
    }
    
    .announcement-card {
      align-items: center; 
      width: 85vw; 
      height: auto; 
    } 

    .artist-link:hover { 
      width: 40vw; 
    }

    #bottom-1 {
      height: 60vh; 
    } 

    #br-mobile {
      display: block; 
    }

    #p-top {
      margin-left: 15px; 
      margin-right: 15px; 
      font-family: 'Roboto', Helvetica, Arial, sans-serif; 
      justify-content: center !important;
    }

    #p-bottom {
      margin-left: 15px; 
      margin-right: 15px; 
      font-family: 'Roboto', Helvetica, Arial, sans-serif; 
      justify-content: center !important; 
    }

    #fung-shway {
      margin-top: 230px;
      margin-left: 5px;
      margin-right: 5px; 
      height: auto !important; 
      width: 60vw !important;  
    } 

    .top-message {
      width: 90vw; 
      border-radius: 15px; 
      border-style: solid;   
      border-color: red; 
      background-color: #1f6edc; 
      display: block; 
      margin-left: auto; 
      margin-right: auto; 
      padding: 10px 10px 10px 10px; 
      margin-top: 15px; 
      margin-bottom: 20px; 
    } 
  
    .inner-message {
      width: 75vw; 
      display: block; 
      margin-left: auto; 
      margin-right: auto; 
      border-radius: 15px; 
      border-style: double; 
      border-color: #fff; 
    }
  
    .celebrate {
      color: #ffffff !important; 
      display: block; 
      margin-top: 15px; 
      margin-left: auto; 
      margin-right: auto; 
      text-transform: uppercase; 
      text-decoration: none;  
      font-weight: bold; 
      font-size: 1.2em;
      text-align: center; 
     } 
  
     .from {
      color: #ffffff !important; 
      display: block; 
      margin-top: 15px; 
      margin-left: auto; 
      margin-right: auto; 
      font-weight: normal; 
      font-size: 0.8em;
      text-align: center; 
     } 
  
     .from-1 {
      color: #ffffff !important; 
      display: block; 
      margin-top: 10px; 
      margin-left: auto; 
      margin-right: auto; 
      font-weight: bold; 
      font-size: 0.9em;
      text-align: center; 
     } 
  
     .from-2 {
      color: #ffffff !important; 
      display: block; 
      margin-top: 10px; 
      margin-left: auto; 
      margin-right: auto; 
      font-weight: bold; 
      font-size: 0.85em;
      text-align: center; 
      padding-right: 5px; 
      padding-left: 5px; 
     } 
  
     .from-3 {
      color: #ffffff !important; 
      display: block; 
      margin-left: auto; 
      margin-right: auto; 
      font-weight: bold; 
      font-size: 0.8em;
      text-align: center; 
      padding-right: 5px; 
      padding-left: 5px; 
      padding-bottom: 5px; 
     } 

     #curve-div {
      height: 98vh;
     }

     .custom-shape-divider-top-1680282774 {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      overflow: hidden;
      line-height: 0;
      transform: rotate(180deg);
    }
  
    .custom-shape-divider-top-1680282774 svg {
      position: relative;
      display: block;
      width: calc(220% + 3.3px);
      height: 269px;
    }
  
    .custom-shape-divider-top-1680282774 .shape-fill {
      fill: #BDBDBD;
    }
  }

  @media only screen and (max-width: 720px) {
    /* #top{
      margin-bottom: 150px; 
    } */
  }