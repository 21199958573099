.header-none {
    display: none; 
}

.header {
    display: block; 
}

.the-gallery {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif !important;
    font-size: 26px;
    display: block; 
    justify-content: center !important; 
    text-align: center !important; 
    align-items: center !important; 
    color: rgb(0, 0, 0); 
    /* color: #1e3272 !important;  */
    font-weight: bold; 
    margin-top: 20px; 
  } 

  #big-name {
    display: block; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 20px; 
    text-align: center; 
    text-decoration: none; 
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 24px !important; 
    font-weight: bold !important;  
    color: black !important; 
  } 

  #ndot {
    display: block; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 20px; 
    text-align: center; 
    text-decoration: none; 
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: normal !important; 
    font-size: 24px !important;  
    color: black !important; 
  } 

  .the-card {
      display: flex; 
      justify-content: center !important; 
      margin-left: auto; 
      margin-right: auto;  
      width: 35vw; 
      margin-top: 40px; 
      background: #1e3272; 
  }

  #caption {
    display: block; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 20px; 
    text-align: center; 
    text-decoration: none; 
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 18px !important; 
    font-weight: bold !important;  
    color: black !important; 
} 
  
.cardTitle { 
    height: 40px; 
    width: 35vw; 
    vertical-align: middle !important; 
    display: flex !important; 
    margin-left: auto; 
    margin-right: auto; 
    justify-content: center; 
    background-color: #1e3272; 
    text-align: center; 
    text-decoration: none; 
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 26px !important; 
    font-weight: bold !important;  
    color: rgb(255, 255, 255) !important; 
} 

.card-row {
    display: flex; 
    align-content: center !important; 
    justify-content: space-evenly; 
} 

.the-gallery-card {
    display: inline-block; 
    margin-left: auto; 
    margin-right: auto; 
    width: 36vw; 
    height: auto; 
}

.the-gallery-card:hover {
    transform: translateY(10px) !important;  
} 

.our-founder-card {
    display: inline-block; 
    margin-left: auto; 
    margin-right: auto; 
    width: 36vw; 
    height: auto; 
}

.our-founder-card:hover {
    transform: translateY(10px) !important;  
}

#art-paragraph-other {
    font-size: 18px !important;  
    text-align: center; 
} 

#caption-small {
    display: block; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 20px; 
    text-align: center; 
    text-decoration: none; 
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 16.5px !important; 
    font-weight: bold !important;  
    color: black !important; 
} 

#link {
    text-decoration: none; 
}

#image-thing-vu {
    display: flex; 
    justify-content: center;  
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 30px; 
    margin-bottom: 20px; 
    vertical-align: middle;
    width: 30vw !important; 
    height: auto; 
} 

#image-thing-vu-2 {
    display: flex; 
    justify-content: center;  
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 50px; 
    /* margin-bottom: 20px;  */
    vertical-align: middle;
    width: 30vw !important; 
    height: auto; 
} 

@media only screen and (max-width: 600px) {

    #art-paragraph {
        margin-left: 15px; 
        margin-right: 15px; 
        text-align: center; 
    }

    #art-paragraph-other {
        margin-left: 15px; 
        margin-right: 15px; 
        text-align: center; 
    } 

    #header-thing {
        justify-content: center !important; 
        text-align: center !important; 
        margin-left: auto; 
    } 

    #image-thing-at {
        width: 90vw !important; 
    } 

    .the-card {
        display: flex; 
        justify-content: center !important; 
        margin-left: auto; 
        margin-right: auto;  
        width: 80vw; 
        margin-top: 40px; 
        background: #1e3272; 
    }

    .cardTitle { 
        height: 40px; 
        width: 80vw; 
        vertical-align: middle !important; 
        display: flex !important; 
        margin-left: auto; 
        margin-right: auto; 
        justify-content: center; 
        background-color: #1e3272; 
        text-align: center; 
        text-decoration: none; 
        font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        font-size: 26px !important; 
        font-weight: bold !important;  
        color: rgb(255, 255, 255) !important; 
    } 

    #image-thing-vu {
        display: flex; 
        justify-content: center;  
        margin-left: auto; 
        margin-right: auto; 
        margin-top: 30px; 
        margin-bottom: 20px; 
        vertical-align: middle;
        width: 75vw !important; 
        height: auto; 
    } 
    
    #image-thing-vu-2 {
        display: flex; 
        justify-content: center;  
        margin-left: auto; 
        margin-right: auto; 
        margin-top: 50px; 
        /* margin-bottom: 20px;  */
        vertical-align: middle;
        width: 75vw !important; 
        height: auto; 
    } 

    .the-gallery-card { 
        display: block; 
        width: 76vw; 
        height: auto; 
    } 

    .our-founder-card { 
        display: block; 
        width: 76vw; 
        height: auto; 
    } 

    .card-row {
        display: block; 
    }

}